import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0574e9da"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "seed-r section" }
const _hoisted_2 = { class: "container-left" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_icon = _resolveComponent("icon")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.currentUserState !== null)
        ? (_openBlock(), _createBlock(_component_router_view, { key: 0 }))
        : (_openBlock(), _createBlock(_component_icon, {
            key: 1,
            name: "loading-big",
            class: "seed-r__loading",
            width: 120,
            height: 120
          }))
    ])
  ]))
}