
import Icon from '@/components/Icon.vue';
import CONSTS from '@/utils/constants';
import { defineComponent, watch, computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
// import { getRouteByUserState } from '@/utils/routerHelper'


export default defineComponent({
  name: 'SeedRound',
  components: { Icon },
  setup (): Record<string, unknown> {
    const store = useStore();
    const router = useRouter()
    const currentUserState = computed(() => store.getters['user/currentState']);
    watch(currentUserState, (newUserState, oldUserState) => {
      // console.log(oldUserState, '-->>', newUserState, CONSTS.OPTIONS_BY_STATE[oldUserState]?.autoRedirect)
      if (!oldUserState || CONSTS.OPTIONS_BY_STATE[oldUserState].autoRedirect) {
        const nextRoute = CONSTS.NAV_ROUTES[CONSTS.NAV.SEED_ROUND]
        if (router.currentRoute.value.name !== nextRoute.NAME) router.replace(nextRoute.PATH)
      }
      // if (oldUserState !== null) return
      // const validRoute = getRouteByUserState(newUserState)
      // if (validRoute) {
      //   router.replace(validRoute)
      // } else {
      //   router.replace(CONSTS.NAV_ROUTES[CONSTS.NAV.ACCOUNT].PATH)
      // }
    });
    return { currentUserState }
  }
});
